@import "/src/style/global.scss";

.flex-container {
  display: flex;

  @media (max-width: $screenPhone) {
    flex-direction: column;
  }

  .col {
    max-width: 100%;
  }
}

.grid-two-even {
  @extend .flex-container;
  align-items: center;
  .col {
    flex: 1;
  }
}

.grid-two {
  @extend .flex-container;
  align-items: center;

  .col:first-child {
    flex: 3;

    @media (min-width: $screenPhone) {
      margin-right: 48px;
    }
  }
  .col:last-child {
    flex: 5;
  }

  &.opposite {
    .col:first-child {
      flex: 5;
    }
    .col:last-child {
      flex: 3;
    }
  }
  @media (max-width: $screenPhone) {
    flex-direction: column;
  }
}

.grid-three {
  @extend .flex-container;
  .col,
  .col-text-with-image {
    flex: 1;
  }

  .col-text-with-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
