@import "/src/style/global.scss";

.container {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .dropdownTitle {
    border: 1px solid black;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
  }
  .dropdownContainer {
    height: 400px;
    overflow: scroll;
    position: absolute;
    background-color: white;
    top: 40px;
    left: 40;
    border: 1px solid black;
    padding: 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    .language {
      padding: 8px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
