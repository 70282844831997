@import "/src/style/global.scss";

.borderBottom {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 70vw;
    left: 15vw;
    height: 1px;
    display: block;
    border-bottom: 2px solid rgba(0, 0, 0, 0.08);
    @media (max-width: $screenPhone) {
      width: 80vw;
      left: 10vw;
    }
  }

  .notifications {
    $padding: 60px;

    > div {
      overflow: visible;
      padding-top: 120px;
      padding-bottom: 120px;
    }
    @media (max-width: $screenPhone) {
      padding-right: $padding;
    }
    .phoneImgForDesktop {
      width: 100%;
      padding-left: $padding;
      @media (max-width: $screenPhone) {
        padding-right: $padding;
        width: auto;
      }

      img {
        width: 100%;
      }
    }

    div[class="float-right"] {
      padding-left: $padding;
      position: relative;

      .smiley {
        position: absolute;
        width: 100%;
        top: -80px;
        left: 0;
        text-align: center;

        img {
          width: 48px;
        }
      }

      @media (max-width: $screenSmall) {
        br {
          display: none;
        }
      }
      @media (max-width: $screenPhone) {
        .smiley {
          top: -20px;
        }
        br.phoneBr {
          display: initial;
        }
        padding-left: 0;
      }
    }
    .phoneImgForMobile {
      display: none;
    }

    @media (max-width: $screenPhone) {
      > div {
        padding-top: 64px;
        padding-bottom: 32px;
      }
      .phoneImgForDesktop {
        display: none;
      }
      div[class="col-text-with-image"] {
        div[class="float-right"] {
          float: initial;
          text-align: center;
          padding-top: 40px;
        }
      }
    }
  }
}

.phoneImgForMobile {
  display: none;
}
@media (max-width: $screenPhone) {
  .phoneImgForMobile {
    display: initial;
    display: flex;
    overflow-y: scroll;
    padding: 0 15vw 64px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
    img {
      padding: 0 12.5vw 0 10vw;
      &:first-child {
        padding: 0;
        margin-left: -2.5vw;
      }
    }
  }
}
