@import "/src/style/global.scss";

.banner {
  margin: 88px 15vw;
  @media (max-width: $screenSmall) {
    margin: 88px 10vw;
  }
  padding: 64px;
  border-radius: 40px;
  background-color: $clrGray;

  h3 {
    padding-left: 100px;
  }
  @media (max-width: $screenPhone) {
    margin: 64px 8vw 48px;
    padding-bottom: 32px;
    h3 {
      padding-left: 0;
      padding-top: 16px;
      text-align: center;
    }
  }
}
