@import "/src/style/global.scss";

.howMuchToSpend {
  > div {
    padding-top: 127px;
    padding-bottom: 127px;
    border-top: 2px solid rgba(0, 0, 0, 0.08);
    position: relative;

    .phone {
      img {
        width: 100%;
      }
    }

    .star1 {
      position: absolute;
      top: 256px;
      left: 64px;
      width: 32px;
    }
    .star2 {
      position: absolute;
      top: 256px;
      right: 10%;
      width: 48px;
    }

    @media (max-width: $screenPhone) {
      padding: 40px 40px 16px;
      .star1,
      .star2 {
        display: none;
      }

      $offset: -50vw;
      .phone {
        padding: 5vw;
        overflow: hidden;
        height: 50%;
        position: relative;
        img {
          z-index: 0;
        }
        &::after {
          content: "";
          height: 100%;
          width: 100%;
          z-index: 0;
          position: absolute;
          left: 0;
          box-shadow: inset 0 $offset 30px 10px #ffffff;
        }
      }
      .howMuchToSave {
        z-index: 9;
        margin-top: $offset;
        h3 {
          margin-top: 64px;
          float: initial !important;
        }
      }
    }
  }
}
