@import "/src/style/global.scss";

.home {
  background-color: $clrGray;
  min-height: 704px;
  .makeEndsMeet {
    max-width: 80%;
  }
  p {
    line-height: 42px;
    .phoneBr {
      display: none;
    }
    @media (max-width: $screenSmall) {
      line-height: 32px;
    }
    @media (max-width: $screenPhone) {
      line-height: 32px;
      br {
        display: none;
        &.phoneBr {
          display: initial;
        }
      }
    }
  }

  .imgCol {
    position: relative;
    text-align: end;
    .phone {
      position: relative;
      margin-bottom: -160px;
      z-index: 1;
      &:nth-child(2) {
        display: none;
      }
    }
  }

  .mobileStar {
    display: none;
  }

  @media (max-width: $screenPhone) {
    min-height: 504px;
    padding-top: 64px;
    align-items: initial;

    .makeEndsMeet {
      width: 235px;
      position: relative;
      .mobileStar {
        display: initial;
        position: absolute;
        bottom: -16px;
        right: -46px;
        height: 32px;
      }
    }
    .col,
    .imgCol {
      flex: initial;
    }
    .imgCol {
      padding: 0 32px;
      margin-top: 32px;
      text-align: center;
      .phone:nth-child(1) {
        display: none;
      }
      .phone:nth-child(2) {
        display: initial;
      }
    }
  }
}
