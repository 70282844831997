@import "/src/style/global.scss";

.nav,
.navPlaceholder {
  height: 108px;
  width: 100%;
  @media (max-width: $screenPhone) {
    height: 64px;
  }
}

.nav {
  z-index: 999;
  width: calc(100% - 80px);
  position: fixed;
  background-color: $clrBackground;
  display: flex;
  align-items: center;
  padding: 0 40px;

  a:first-child {
    margin-left: 0;
    padding: 0;
    img:last-child {
      display: none;
    }
  }

  .links {
    position: relative;
    overflow: hidden;
    padding: 24px 0;
    a {
      font-weight: 500;
      margin-left: 48px;
      padding: 0 24px;
      @media (max-width: $screenPhone) {
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          padding-right: 16px;
        }
      }
    }

    &::after {
      content: "";
      background-color: $clrYellow;
      height: 2px;
      width: 64px;

      position: absolute;
      bottom: 8px;
      transition: 0.3s ease-in-out;

      @media (max-width: $screenSmall) {
        width: 44px;
        bottom: 12px;
      }
      @media (max-width: $screenPhone) {
        width: 40px;
        bottom: 12px;
      }
    }

    &.home::after {
      left: -200px;
    }
    &.features::after {
      left: 75px;
    }
    &.moneyFlow::after {
      left: 200px;
    }

    @media (max-width: $screenSmall) {
      &.features::after {
        left: 75px;
      }
      &.moneyFlow::after {
        left: 180px;
      }
    }

    @media (max-width: $screenPhone) {
      &.features::after {
        left: 27px;
      }
      &.moneyFlow::after {
        left: 128px;
      }
    }
  }

  a:last-child {
    margin-left: auto;
  }
  a:nth-child(2) {
    margin-left: auto;
  }
  @media (max-width: $screenPhone) {
    width: calc(100% - 48px);
    padding: 0 24px;
    & > a:last-child {
      display: none;
    }
    a:first-child {
      margin-right: auto;
      img:first-child {
        display: none;
      }
      img:last-child {
        display: block;
      }
    }
  }
}
