@import "/src/style/global.scss";

.header {
  h1 {
    margin-top: 124px;
    margin-bottom: 64px;
  }
  @media (max-width: $screenPhone) {
    h1 {
      display: none;
    }
    h2 br {
      display: none;
    }
  }

  text-align: center;
  margin-bottom: 100px;
}

@media (min-width: $screenPhone) {
  .featureCard {
    margin-top: 64px;
  }
}

section[id="features"] {
  overflow: auto;
  padding-bottom: 128px;

  @media (max-width: $screenPhone) {
    padding-bottom: 12px;
  }
}
